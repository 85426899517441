<template>
  <!-- <div style=" text-align: center;height: 50px;">
    <span style="font-size: 24px;color: black;font-weight: bold;font-family: 仿宋;">平台</span>
    <span style="font-size: 26px;color: rgba(241, 111, 5, 0.87);font-weight: bold;font-family: 仿宋;">小黑板</span>
  </div> -->

  <div style=" text-align: center;height: 50px;">
    <span style="font-size: 24px;color: black;font-weight: bold;font-family: 仿宋;"></span>
    <span style="font-size: 26px;color: rgba(67, 29, 235, 0.815);font-weight: bold;font-family: 仿宋;">项目资源</span>
  </div>
 
  <!-- <div style="height:500px;text-align: center;">
    <el-carousel  trigger="click"  :interval="2000" arrow="always" 
    style="height:500px;background-color: rgb(44, 118, 167);border: 1px solid rgb(207, 201, 201);margin-left:5px;margin-right:5px">
      <el-carousel-item style="height: 500px">
        <img
         style="width: 100%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/zhihuishuiwu.png"
        >
       <br>
       青岛西海岸智慧水务平台
       
      </el-carousel-item>
      <el-carousel-item style="height: 500px">
        <img
         style="width: 100%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/zhinengjiance.png"
        >
       <br>
       馈线自动化智能测试系统
      </el-carousel-item>
      <el-carousel-item style="height: 500px">
        <img
         style="width: 100%; height:90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/danganguanli.png"
        >
       <br>
       苏州工业园区档案管理系统
      </el-carousel-item>
      <el-carousel-item style="height: 500px">
        <img
         style="width: 100%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/shujuzonghefenxi.png"
        >
       <br>
       测试数据综合评价系统
      </el-carousel-item>
      <el-carousel-item style="height: 500px">
        <img
         style="width: 100%; height:90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/piaocangguanli.png"
        >
       <br>
       青岛滨海学院博物馆票仓管理系统
      </el-carousel-item>
     
    </el-carousel>

  </div> -->
  <div style="height:460px;">
    <el-row style="height:40%;margin-bottom: 15px;">
      <el-col :span="6" style="height:100%">
        <img
         style="width: 80%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/danganguanli.png"
        >
       <br>
       苏州工业园区档案管理系统
      </el-col>
      <el-col :span="6" style="height:100%">
        <img
         style="width: 80%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/zhinengjiance.png"
        >
       <br>
       馈线自动化智能测试系统
      </el-col>
      <el-col :span="6" style="height:100%">
        <img
         style="width: 80%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/shujuzonghefenxi.png"
        >
       <br>
       测试数据综合评价系统
      </el-col>
      <el-col :span="6" style="height:100%">
        <img
         style="width: 80%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/piaocangguanli.png"
        >
       <br>
       青岛滨海学院博物馆票仓管理系统
      </el-col>
     </el-row>
    <el-row style="height:60%">
      <el-col :span="6" style="height:100%">
        <img
         style="width: 50%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/haixinrili.png"
        >
       <br>
       海信日立SMS系统
      </el-col>
      <el-col :span="6" style="height:100%">
        <img
         style="width: 50%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/tingnanmeikuang.png"
        >
       <br>
       陕西亭南煤矿TPM系统
      </el-col>
      <el-col :span="6" style="height:100%">
        <img
         style="width: 50%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/pingduqungong.png"
        >
       <br>
       平度群众工作平台
      </el-col>
      <el-col :span="6" style="height:100%">
        <img
         style="width: 50%; height: 90%;border: 1px solid rgb(223, 205, 205);"
         src="../assets/img/xinhemeikuang.jpg"
        >
       <br>
       山东新河煤矿TPM系统
      </el-col>
     </el-row>
 
  </div>
  <!-- 视频资源 -->
  <div style=" text-align: center;height: 50px;margin-top: 20px;">
    <span style="font-size: 24px;color: black;font-weight: bold;font-family: 仿宋;"></span>
    <span style="font-size: 26px;color: #f08047;font-weight: bold;font-family: 仿宋;">教学视频</span>
  </div>
  <div>
    
    <el-row>
      <el-col style="text-align:left;border: 1px solid rgb(163, 158, 158);">
        <router-link   v-for="item in videoList" :key=item.id style="margin-left:10px"
         slots="a" 
         target="_blank"
         :to="{name:'video',params:{id: item.id}}"
                               class="routerWork">
            <el-image  
             style="border: 1px solid rgb(223, 205, 205); width:240px;height:150px;border-radius:5px;margin:5px"
             :src="item.image"
             /> 
            </router-link>
      </el-col>
    </el-row>
  </div>
  <show-video-dialog v-if="showVideoVisibel" ref="showvideo" ></show-video-dialog>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import showVideoDialog from './video/showVideoDialog.vue'
export default {
  name: 'HomeView',
  data(){
    return{
      videoList:[],
      showVideoVisibel:false
    }
  },
  mounted(){
  this.loadVideo()
  },
  methods:{
    showVideo(id){
      this.showVideoVisibel = true
        this.$nextTick(() => {
          this.$refs.showvideo.init(id)
        })
    },
    loadVideo(){
      this.axios({
            url: '/video/getVideoList.do',
            method: 'post',
            params: {
            }
          }).then(data => {
           this.videoList=data.data;
          }).catch(data =>{
          })
    }
  },
  components: {
    HelloWorld,
    showVideoDialog
  }
}
</script>
